import useVersionTesting from './useVersionTesting'

export default function useLeadButton({ id, provider, idPrefix }: { id: string; provider?: string; idPrefix: string }) {
  const versionTesting = useVersionTesting()
  const link = `/assinar/lead/${id}${provider ? `?origin=${provider}` : ''}`
  const idLabel = `${idPrefix}-${versionTesting}`
  const buttonLabel = versionTesting === 'a' ? 'Solicitar proposta' : 'Assinar agora'

  return { buttonLabel, link, idLabel }
}
