import LocalOfferIcon from '@mui/icons-material/LocalOffer'
import Button from '@mui/material/Button'
import NextLink from 'next/link'
import React from 'react'

import useLeadButton from '../hooks/useLeadButton'

interface iOfferButtonProps {
  data: ResultCard
  origin?: string
}

const OfferButton: React.FC<iOfferButtonProps> = ({ data, origin }) => {
  const { buttonLabel, idLabel, link } = useLeadButton({
    id: data.id,
    idPrefix: 'leadFormButton',
    provider: origin || data?.brand || ' Fale agora'
  })

  return (
    <>
      <NextLink href={link} passHref legacyBehavior>
        <Button data-cy="offerButton" id={idLabel} startIcon={<LocalOfferIcon />} fullWidth variant="contained">
          {buttonLabel}
        </Button>
      </NextLink>
    </>
  )
}

export default OfferButton
