import { NextRequest, NextResponse } from 'next/server'

export const COOKIE_NAME = 'version-testing'

const MARKETING_BUCKETS = ['a', 'b'] as const
export const getVersion = () => MARKETING_BUCKETS[Math.floor(Math.random() * MARKETING_BUCKETS.length)]

export function buildCookieVersionTest(req: NextRequest, res: NextResponse) {
  if (!req.cookies.has(COOKIE_NAME)) {
    const version = getVersion()
    res.cookies.set(COOKIE_NAME, version)
  }
}
